import { enUS, es } from "date-fns/locale";
import i18next from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

i18next
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./locales/${language}/${namespace}.json`),
    ),
  )
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    defaultNS: "common",
    ns: ["common", "companies", "auth", "admin", "contract"],
  });

const dateLocale = () =>
  ({
    en: enUS,
    es,
  })[i18next.language];

export { dateLocale };

export default i18next;

import { ButtonProps } from "@/components/ui/button";

// Given interface with nested, optional values, create another type where all values
// are required. Example:
//
// interface Foo {
//   a?: {
//     b?: string;
//   },
//   c?: number
// }
//
// we can then declare
//
// type Bar = RecursiveRequired<Foo>
//
// which is equivalent to
//
// interface Bar {
//   a: {
//     b: string;
//   },
//   c: number
// }
type RecursiveRequired<T> = Required<{
  [P in keyof T]: T[P] extends object | undefined
    ? RecursiveRequired<Required<T[P]>>
    : T[P];
}>;

type ButtonVariant = Pick<ButtonProps, "variant">["variant"];

export interface NavTheme {
  header?: {
    logo?: {
      href?: string;
      className?: string;
    };
    chevron?: {
      variant?: ButtonVariant;
      className?: string;
      color?: string;
    };
  };
  main?: {
    className?: string;
  };
  tabs?: {
    link?: {
      variant?: ButtonVariant;
      className?: string;
      activeClassName?: string;
    };
    separator?: {
      className?: string;
    };
  };
}

type CompleteNavTheme = RecursiveRequired<NavTheme>;

export const defaultTheme: CompleteNavTheme = {
  header: {
    logo: {
      href: "/",
      className: "outline-primary",
    },
    chevron: {
      variant: "ghost",
      color: "currentColor",
      className:
        "text-secondary-foreground hover:bg-transparent hover:text-primary",
    },
  },
  main: {
    className: "bg-white",
  },
  tabs: {
    link: {
      variant: "ghost",
      className:
        "gap-4 h-fit text-secondary-foreground hover:bg-white hover:text-primary h-16 sm:h-8",
      activeClassName: "bg-moraki-background hover:bg-moraki-background",
    },
    separator: {
      className: "bg-muted-foreground/10",
    },
  },
};

export const attrWithFallback = (theme: NavTheme, accessors: string[]): any => {
  const result = accessors.reduce((acc: any, accessor: string) => {
    if (!acc) return undefined;
    return acc[accessor];
  }, theme);

  if (!result) return attrWithFallback(defaultTheme, accessors);

  return result;
};

import { createContext, useContext } from "react";
import { createStore } from "zustand";

export interface SidebarProps {
  shown: boolean;
}
export interface SidebarState extends SidebarProps {
  toggle: () => void;
  setShown: (show: boolean) => void;
}

const createSidebarStore = (initProps?: Partial<SidebarProps>) => {
  const localStorageValue = localStorage.getItem("sidebar-toggled");

  const DEFAULT_PROPS: SidebarProps = {
    shown: localStorageValue
      ? localStorageValue === "true"
      : initProps
        ? !!initProps.shown
        : true,
  };

  return createStore<SidebarState>()((set) => ({
    ...DEFAULT_PROPS,
    toggle: () =>
      set((state) => {
        const shown = !state.shown;
        localStorage.setItem("sidebar-toggled", shown.toString());
        return { shown };
      }),
    setShown: (shown) =>
      set(() => {
        localStorage.setItem("sidebar-toggled", shown.toString());
        return { shown };
      }),
  }));
};

type SidebarStore = ReturnType<typeof createSidebarStore>;

const SidebarContext = createContext<SidebarStore | null>(null);

const SidebarStoreProvider = SidebarContext.Provider;

const useSidebarStore = () => {
  const store = useContext(SidebarContext);

  if (!store) throw new Error("SidebarStoreProvider not found");

  return store;
};

export { SidebarStoreProvider, createSidebarStore, useSidebarStore };

import { companies, usersSessions } from "@/api";
import AvatarWithFallback from "@/components/ui/avatar-with-fallback";
import { buttonVariants } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useCurrentCompany from "@/lib/hooks/use-current-company";
import usePageProps from "@/lib/hooks/use-page-props";
import { cn, indexBy } from "@/lib/utils";
import { CompanyIndex } from "@/types/serializers";
import { Link, router } from "@moraki/inertia-react";
import { t } from "i18next";
import { capitalize, truncate } from "lodash";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import Icon from "@/components/ui/icon";
import { Check } from "lucide-react";
import { useEffect, useRef, useState } from "react";

const Companies = () => {
  const currentCompany = useCurrentCompany();
  const { user_companies } = usePageProps<{ user_companies: CompanyIndex[] }>();
  const companiesBySlug = indexBy(user_companies, "slug");
  const searchRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const filterFn = (value: string, search: string) =>
    companiesBySlug[value].name
      .toLocaleLowerCase()
      .includes(search.toLocaleLowerCase())
      ? 1
      : 0;

  useEffect(() => {
    setTimeout(() => searchRef.current?.focus(), 300);
  }, []);

  return (
    <DropdownMenuItem
      asChild
      onFocusCapture={(e) => {
        e.preventDefault();
        e.stopPropagation();
        searchRef.current?.focus();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className="focus:!bg-white"
    >
      <Command className="min-w-full p-0" loop filter={filterFn}>
        <CommandInput
          ref={searchRef}
          placeholder={t("select.search")}
          className="search-input"
          onClick={(e) => e.stopPropagation()}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              const selected = optionsRef.current?.querySelector(
                "[data-selected]",
              ) as HTMLDivElement;

              selected.click();
            }
          }}
        />
        <CommandEmpty>{t("select.noResults")}</CommandEmpty>
        <CommandGroup
          className={cn("sm:max-h-48 sm:overflow-auto min-w-full")}
          ref={optionsRef}
        >
          {user_companies?.map((company) => (
            <CommandItem
              key={`search-with-select-option-key-${company.slug}`}
              value={company.slug}
              onSelect={(value) => {
                if (value === currentCompany.slug) return;

                setLoading(true);
                router.get(
                  companies.show.path(companiesBySlug[value]),
                  undefined,
                  {
                    onFinish: () => setLoading(false),
                  },
                );
              }}
              className="group"
            >
              <Check
                className={cn(
                  "mr-2 h-4 w-4",
                  currentCompany.slug == company.slug
                    ? "opacity-100"
                    : "opacity-0",
                  loading && "group-data-[selected=true]:hidden",
                )}
              />
              {loading && (
                <Icon
                  name="loader-circle"
                  className="hidden animate-spin group-data-[selected=true]:block mr-2 h-4 w-4"
                />
              )}
              {company.name}
            </CommandItem>
          ))}
        </CommandGroup>
      </Command>
    </DropdownMenuItem>
  );
};

export default function CompanyDropdown({
  onlyCompanies,
}: {
  onlyCompanies?: boolean;
}) {
  const company = useCurrentCompany();
  const { user_companies } = usePageProps<{ user_companies: CompanyIndex[] }>();

  const otherCompanies = user_companies.filter((c) => c.slug !== company.slug);
  const hasOtherCompanies = otherCompanies.length > 0;

  if (onlyCompanies && !hasOtherCompanies) return null;

  return (
    <div data-testid="company-dropdown">
      <DropdownMenu>
        <DropdownMenuTrigger
          data-qa="company-link"
          className={cn(
            buttonVariants({ variant: "link" }),
            "hover:no-underline gap-2 ml-2",
          )}
          role="trigger"
        >
          <AvatarWithFallback avatarable={company} size="lg" />
          <h4 className="font-semibold">
            {truncate(capitalize(company.name), { length: 20 })}
          </h4>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          side="bottom"
          className="min-w-[263px] ml-8"
          role="menu"
        >
          {!onlyCompanies && (
            <>
              <DropdownMenuGroup>
                <DropdownMenuItem asChild>
                  <Link href={companies.show.path(company)}>
                    {t("dashboard.title")}
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
            </>
          )}

          {onlyCompanies ? (
            <DropdownMenuGroup>
              <Companies />
            </DropdownMenuGroup>
          ) : (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                {t("companies.switch")}
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <Companies />
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          )}

          {!onlyCompanies && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem asChild>
                  <Link href={companies.new.path()}>{t("companies.new")}</Link>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => router.delete(usersSessions.destroy.path())}
                >
                  {capitalize(t("logout").toLowerCase())}
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

// JsFromRoutes CacheKey ab0c64502726206a794f837e6f213380
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/credit_lines'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/credit_lines/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/credit_lines/:id'),
}

// JsFromRoutes CacheKey 21d172a1c4e35a104a64eb70ef1a68ed
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/members'),
  create: /* #__PURE__ */ definePathHelper('post', '/c/:company_slug/members'),
  update: /* #__PURE__ */ definePathHelper('patch', '/c/:company_slug/members/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/c/:company_slug/members/:id'),
}

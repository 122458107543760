// JsFromRoutes CacheKey 407cc8d785693de4deb2182c0ce070b2
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/people'),
  create: /* #__PURE__ */ definePathHelper('post', '/c/:company_slug/people'),
  new: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/people/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/people/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/c/:company_slug/people/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/c/:company_slug/people/:id'),
}

import { cn } from "@/lib/utils";
import MorakiLogo from "../icons/moraki-logo";
import styles from "./loading-dots.module.css";

interface LoadingDotsProps {
  color?: string;
  fullScreen?: boolean;
  className?: string;
}

const LoadingDots = ({
  color = "hsl(var(--accent-foreground))",
  fullScreen,
  className,
}: LoadingDotsProps) => {
  return (
    <div
      className={cn(
        styles.loading,
        fullScreen &&
          "min-h-screen justify-center items-center w-full flex flex-col gap-4",
      )}
    >
      {fullScreen && <MorakiLogo className="max-w-[120px]" />}
      <div
        className={cn(
          "min-w-[30px] flex items-center justify-center",
          className,
        )}
      >
        <span style={{ backgroundColor: color }} />
        <span style={{ backgroundColor: color }} />
        <span style={{ backgroundColor: color }} />
      </div>
    </div>
  );
};

export default LoadingDots;

export function currentPathnameMatchesTemplate(pathTemplate: string): boolean {
  const path = window.location.pathname;
  const templateParts = pathTemplate.split("/");
  const pathParts = path.split("/");

  if (templateParts.length !== pathParts.length) {
    return false;
  }

  for (let i = 0; i < templateParts.length; i++) {
    if (templateParts[i].startsWith(":")) {
      continue;
    }
    if (templateParts[i] !== pathParts[i]) {
      return false;
    }
  }

  return true;
}

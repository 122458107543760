// JsFromRoutes CacheKey 70a7966d1b75b66839b129215cbff97b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/inventory_items'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/inventory_items/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/inventory_items/:id'),
}

import { useStore } from "zustand";
import { useSidebarStore } from "../stores/sidebar-store";

export default function useSidebar() {
  const store = useSidebarStore();
  const sidebarShown = useStore(store, (s) => s.shown);
  const setSidebarShown = useStore(store, (s) => s.setShown);
  const toggleSidebar = useStore(store, (s) => s.toggle);

  return { sidebarShown, setSidebarShown, toggleSidebar };
}

import { usersSessions } from "@/api";
import { Button, ButtonProps } from "@/components/ui/button";
import Icon from "@/components/ui/icon";
import { router } from "@moraki/inertia-react";

export default function LogoutButton({ children, ...rest }: ButtonProps) {
  return (
    <Button
      onClick={() => router.delete(usersSessions.destroy.path())}
      variant="ghost"
      size={children ? "sm" : "icon"}
      className="text-secondary-foreground hover:bg-white hover:text-primary justify-start gap-4"
      {...rest}
    >
      <Icon name="power" size={24} />
      {children}
    </Button>
  );
}

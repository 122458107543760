import useAdminAuth from "@/lib/hooks/use-admin-auth";
import useAuth from "@/lib/hooks/use-auth";
import useEnvironment from "@/lib/hooks/use-environment";
import { getMeta } from "@/lib/utils";
import * as Sentry from "@sentry/react";
import { ReactNode } from "react";

const dsn = getMeta("sentry-dsn") || undefined;
let sentryInitialized = false;

export default function SentryProvider({ children }: { children: ReactNode }) {
  const auth = useAuth() || useAdminAuth();
  const env = useEnvironment();

  if (!sentryInitialized) {
    Sentry.init({
      dsn,
      enabled: env === "production",
      tracePropagationTargets: [window.location.host],

      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          networkDetailAllowUrls: [window.location.origin],
        }),
      ],
    });
    sentryInitialized = true;
  }

  if (auth) Sentry.setUser({ email: auth.email, id: auth.id });

  return children;
}

// JsFromRoutes CacheKey 21b6aa5fbefc2702e74e28c4608ee3f8
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/credit_line_request/new'),
  show: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/credit_line_request'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/c/:company_slug/credit_line_request'),
  create: /* #__PURE__ */ definePathHelper('post', '/c/:company_slug/credit_line_request'),
}

// JsFromRoutes CacheKey 2f45add35b7321cc774d38d773aa827d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/login'),
  create: /* #__PURE__ */ definePathHelper('post', '/login'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/logout'),
}

import { Toaster } from "@/components/ui/toaster";
import { TooltipProvider } from "@/components/ui/tooltip";
import { ReactNode } from "react";
import { QueryParamProvider } from "use-query-params";
import { WindowHistoryAdapter } from "use-query-params/adapters/window";
import LanguageProvider from "./language-provider";
import SentryProvider from "./sentry-provider";
import StripePromiseProvider from "./stripe-promise-provider";

export interface CommonProvidersProps {
  children: ReactNode;
  noToasts?: boolean;
}

export default function CommonProviders({
  children,
  noToasts,
}: CommonProvidersProps) {
  return (
    <QueryParamProvider adapter={WindowHistoryAdapter}>
      <StripePromiseProvider>
        <SentryProvider>
          <LanguageProvider>
            <TooltipProvider>
              {!noToasts && <Toaster />}
              {children}
            </TooltipProvider>
          </LanguageProvider>
        </SentryProvider>
      </StripePromiseProvider>
    </QueryParamProvider>
  );
}

import { useEffect, useState } from "react";
import { Config } from "tailwindcss";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../tailwind.config";

const fullConfig = resolveConfig(tailwindConfig) as Config;

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const breakpoints = fullConfig.theme?.screens as unknown as Record<
    string,
    string
  >;

  const desktopBreakpoint =
    parseInt(breakpoints["sm"]?.replace("px", "")) || 640;

  const mediumDesktopBreakpoint =
    parseInt(breakpoints["md"]?.replace("px", "")) || 768;

  return {
    windowSize,
    isMobile:
      typeof windowSize?.width === "number" &&
      windowSize?.width < desktopBreakpoint,
    isDesktop:
      typeof windowSize?.width === "number" &&
      windowSize?.width >= desktopBreakpoint,
    isMediumDesktop:
      typeof windowSize?.width === "number" &&
      windowSize?.width >= mediumDesktopBreakpoint,
  };
}

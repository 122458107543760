import { IconProps } from "@/components/ui/icon";
import { usePage } from "@moraki/inertia-react";

export type Flash = {
  title: string;
  description?: string;
  icon?: IconProps["name"];
};

export default function useFlashNotifications() {
  const { props } = usePage();
  const flash = props.flash as { success?: Flash; alert?: Flash } | undefined;
  return flash;
}

import { cn } from "@/lib/utils";
import { camelCase, upperFirst } from "lodash";
import { LucideProps } from "lucide-react";
import * as icons from "./icons";
import LazyIcon, { LazyIconProps } from "./lazy-icon";

export type IconName = keyof typeof icons;

export interface IconProps extends LucideProps {
  name: LazyIconProps["name"];
  size?: number;
}

export default function Icon({ name, size = 16, ...rest }: IconProps) {
  const normalizedName = upperFirst(camelCase(name)) as IconName;

  const EagerIcon = icons[normalizedName];

  if (!EagerIcon)
    console.warn(
      `Icon ${name} is being lazy loaded. Consider eager loading it.`,
    );

  const LucideIcon = EagerIcon || LazyIcon;

  return (
    <LucideIcon
      size={size}
      {...rest}
      name={name}
      className={cn("min-w-fit min-h-fit", rest.className)}
    />
  );
}

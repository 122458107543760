import useWindowSize from "@/lib/hooks/use-window-size";
import {
  SidebarStoreProvider,
  createSidebarStore,
} from "@/lib/stores/sidebar-store";
import { ReactNode, useRef } from "react";
import CommonProviders from "./common-providers";

export default function DashboardProviders({
  children,
}: {
  children: ReactNode;
}) {
  const { isMobile } = useWindowSize();
  const sidebarStore = useRef(createSidebarStore({ shown: !isMobile })).current;
  return (
    <SidebarStoreProvider value={sidebarStore}>
      <CommonProviders>{children}</CommonProviders>
    </SidebarStoreProvider>
  );
}

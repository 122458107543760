// JsFromRoutes CacheKey 6e0d764db53737b7fe0a6692177bd7da
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/credit_line_requests'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/credit_line_requests/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/credit_line_requests/:id'),
}

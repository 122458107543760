// Entry point for the build script in your package.json
import "@/i18n";
import { InertiaProgress } from "@inertiajs/progress";
import { createInertiaApp } from "@moraki/inertia-react";
import axios from "axios";
import { createRoot } from "react-dom/client";
import DashboardLayout from "../layouts/dashboard-layout";
import "./application.css";

type Page = {
  layout?: typeof DashboardLayout;
};

const pages = import.meta.glob("../pages/**/*.tsx") as Record<
  string,
  () => Promise<{ default: Page }>
>;

InertiaProgress.init();

document.addEventListener("DOMContentLoaded", () => {
  const csrfToken = (
    document.querySelector("meta[name=csrf-token]") as HTMLMetaElement
  )?.content;

  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;

  createInertiaApp({
    resolve: async (name) => {
      const page = (await pages[`../pages/${name}.tsx`]()).default;
      page.layout = page.layout || DashboardLayout;
      return page;
    },
    setup({ el, App, props }) {
      createRoot(el).render(<App {...props} />);
    },
  });
});

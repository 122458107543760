import useSidebar from "@/lib/hooks/use-sidebar";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

export default function MainPanel({ children }: { children: ReactNode }) {
  const { sidebarShown } = useSidebar();
  return (
    <div
      className={cn(
        "h-1 min-h-screen transition-all",
        sidebarShown && "sm:pl-sidebar",
      )}
    >
      {children}
    </div>
  );
}

import useSidebar from "@/lib/hooks/use-sidebar";
import useWindowSize from "@/lib/hooks/use-window-size";
import { Button, ButtonProps } from "./ui/button";
import Icon from "./ui/icon";

export default function NavToggle(props: ButtonProps) {
  const { toggleSidebar } = useSidebar();
  const { isMobile } = useWindowSize();

  return (
    <Button onClick={toggleSidebar} size="icon" variant="ghost" {...props}>
      <Icon name="menu" size={isMobile ? 24 : 16} />
    </Button>
  );
}

// JsFromRoutes CacheKey 044c0ea762681de2f32f9c0efffda865
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/draws'),
  create: /* #__PURE__ */ definePathHelper('post', '/c/:company_slug/draws'),
  new: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/draws/new'),
}

import useEnvironment from "@/lib/hooks/use-environment";
import { getMeta } from "@/lib/utils";
import { loadStripe } from "@stripe/stripe-js";
import React, { ReactNode, useContext } from "react";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const publishableKey = getMeta("stripe-publishable-key");
const stripePromise = publishableKey && loadStripe(publishableKey);
const StripeContext = React.createContext<typeof stripePromise>(null);

export function useStripePromise() {
  return useContext(StripeContext);
}

export default function StripePromiseProvider({
  children,
}: {
  children: ReactNode;
}) {
  const env = useEnvironment();

  if (env === "test") return children;

  return (
    <StripeContext.Provider value={stripePromise}>
      {children}
    </StripeContext.Provider>
  );
}

import { cn } from "@/lib/utils";

type Color = "sky" | "red" | "primary";

const INNER_COLORS = {
  sky: "bg-sky-500",
  red: "bg-red-500",
  primary: "bg-primary",
} as Record<Color, string>;

const PING_COLORS = {
  sky: "bg-sky-400",
  red: "bg-red-400",
  primary: "bg-amber-600",
} as Record<Color, string>;

export default function PingIndicator({
  color = "sky",
  className,
}: {
  color?: Color;
  className?: string;
}) {
  return (
    <span className={cn("relative flex h-2 w-2", className)}>
      <span
        className={cn(
          "animate-ping absolute inline-flex h-full w-full rounded-full opacity-75",
          PING_COLORS[color],
        )}
      ></span>
      <span
        className={cn(
          "relative inline-flex rounded-full h-2 w-2",
          INNER_COLORS[color],
        )}
      ></span>
    </span>
  );
}

export default function MorakiLogo({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 313.3 81.14"
      className={className}
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            className="fill-[#503e3c] stroke-none dark:fill-muted-foreground"
            d="m20.85,23.85c7.74,0,13.42,3.1,16.52,7.95,3.1-4.85,8.77-7.95,16.62-7.95,13.01,0,20.96,8.67,20.96,23.33v32.52h-12.8v-32.83c0-6.92-3.3-11.05-8.98-11.05s-9.29,4.13-9.29,11.46v32.41h-12.8v-32.41c0-7.33-3.51-11.46-9.29-11.46s-8.98,4.13-8.98,11.05v32.83H0v-32.52c0-14.66,7.85-23.33,20.85-23.33Z"
          />
          <path
            className="fill-[#503e3c] stroke-none dark:fill-muted-foreground"
            d="m84.23,52.44c0-16.62,12.59-28.59,28.59-28.59s28.59,11.97,28.59,28.59-12.59,28.7-28.59,28.7-28.59-12.08-28.59-28.7Zm28.59,16.62c8.98,0,15.79-6.81,15.79-16.62s-6.81-16.62-15.79-16.62-15.79,6.81-15.79,16.62,6.81,16.62,15.79,16.62Z"
          />
          <path
            className="fill-[#503e3c] stroke-none dark:fill-muted-foreground"
            d="m169.09,25.08h6.61v11.56h-5.37c-4.65,0-6.5,1.86-6.5,6.61v36.44h-12.8v-36.13c0-12.8,6.4-18.48,18.06-18.48Z"
          />
          <path
            className="fill-[#503e3c] stroke-none dark:fill-muted-foreground"
            d="m179.41,52.44c0-16.83,12.28-28.39,27.15-28.39,7.12,0,13.73,2.68,17.65,7.64l.1-6.61h11.36v54.61h-11.36l-.1-6.61c-3.92,4.95-10.53,7.64-17.65,7.64-14.87,0-27.15-11.66-27.15-28.28Zm28.8,16.52c8.67,0,15.59-6.09,15.59-16.52s-6.92-16.62-15.59-16.62c-9.19,0-16,6.71-16,16.62s6.81,16.52,16,16.52Z"
          />
          <path
            className="fill-[#503e3c] stroke-none dark:fill-muted-foreground"
            d="m261.27,55.54v24.15h-12.8V0h12.8v46.76l17.34-21.68h14.55l-20.13,25.7,21.16,28.9h-15.38l-17.55-24.15Z"
          />
          <path
            className="fill-[#503e3c] stroke-none dark:fill-muted-foreground"
            d="m300.5,0h12.8v16h-12.8V0Zm0,25.08h12.8v54.61h-12.8V25.08Z"
          />
        </g>
      </g>
    </svg>
  );
}

import i18n from "@/i18n";
import Currency from "@/types/Currency";
import { clsx, type ClassValue } from "clsx";
import { t } from "i18next";
import { isUndefined, transform } from "lodash";
import { ReactElement, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getLocaleCurrency = () => {
  const currency = t("currency") as Currency;

  const sample = Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency,
  }).formatToParts(100000.1);

  const decimalSeparator = sample.find((part) => part.type === "decimal")
    ?.value;
  const groupSeparator = sample.find((part) => part.type === "group")?.value;
  const symbol = sample.find((part) => part.type === "currency")?.value;

  return {
    name: currency,
    decimalSeparator,
    groupSeparator,
    symbol,
  };
};

export const formatCurrency = (
  cents: number | undefined | null,
  currency: Currency = t("currency"),
) => {
  if (cents === undefined || cents === null) return;

  const isExact = cents % 100 === 0;

  return t("format.currency", {
    value: cents / 100,
    formatParams: {
      value: {
        currency: currency.toLocaleUpperCase(),
        maximumFractionDigits: isExact ? 0 : 2,
      },
    },
  });
};

export const formatPercentage = (percentage?: number) => {
  if (isUndefined(percentage)) return;

  const casted = Number(percentage);
  const fixTo = casted % 1 === 0 ? 0 : 2;

  return t("format.percentage", {
    value: casted.toFixed(fixTo),
  });
};

const DEFAULT_DATE_FORMAT = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
} as Intl.DateTimeFormatOptions;

export const DAY_OF_YEAR_DATE_FORMAT = {
  year: "numeric",
  month: "long",
  day: "numeric",
} as Intl.DateTimeFormatOptions;

export const DATE_TIME_FORMAT = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
} as Intl.DateTimeFormatOptions;

export const SHORT_DATE_FORMAT = {
  day: "2-digit",
  month: "short",
  year: "numeric",
} as Intl.DateTimeFormatOptions;

export const formatDateTime = (
  isoDate: Date | string | undefined,
  format: Intl.DateTimeFormatOptions = DEFAULT_DATE_FORMAT,
) => {
  if (!isoDate) return;
  const date = castToDate(isoDate);

  return t("format.dateTime", {
    value: date,
    formatParams: {
      value: format,
    },
  });
};

export const castToDate = (isoDate: Date | string | undefined) =>
  typeof isoDate === "string" ? new Date(isoDate) : isoDate;

export const isReactElement = (node: ReactNode): node is ReactElement => {
  return (
    !!node &&
    typeof node !== "string" &&
    typeof node !== "number" &&
    typeof node !== "boolean" &&
    "props" in node
  );
};

export function getNodeText(node: ReactNode): string {
  if (typeof node === "string" || typeof node === "number")
    return node.toString();
  if (node instanceof Array) return node.map(getNodeText).join("");
  if (isReactElement(node)) return getNodeText(node.props.children);
  return "";
}

export const randomKey = () => Math.random().toString(36).slice(2);

export const isAdmin = () => window.location.pathname.startsWith("/admin");

export const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const cameraAvailable = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const cameras = devices.filter((device) => device.kind === "videoinput");
  return cameras.length > 0;
};

export const getMeta = (metaName: string) => {
  const metas = document.getElementsByTagName("meta");

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i].getAttribute("content");
    }
  }

  return "";
};

export function indexBy<T>(
  collection: T[] | undefined,
  indexKey: string,
): Record<string, T>;
export function indexBy<T>(
  collection: T[] | undefined,
  indexKey: string,
  value: (v: T) => any,
): Record<string, any>;
export function indexBy<T>(
  collection: T[] | undefined,
  indexKey: string,
  value?: keyof T | ((v: T) => T[keyof T]),
): Record<string, T | T[keyof T]> {
  if (!collection) return {};

  return transform(
    collection,
    (result, v) => {
      const resultKey = (v[indexKey as keyof T] as string).toLowerCase();
      result[resultKey] = value
        ? typeof value === "function"
          ? value(v)
          : v[value]
        : v;
      return result;
    },
    {} as Record<string, T | T[keyof T]>,
  );
}

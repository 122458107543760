import { LucideProps } from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import { Suspense, lazy } from "react";

export type IconName = keyof typeof dynamicIconImports;

export interface LazyIconProps extends LucideProps {
  name: IconName;
  color?: string;
  size?: number;
}

export default function LazyIcon({ name, size = 16, ...rest }: LazyIconProps) {
  const LucideIcon = lazy(dynamicIconImports[name]);

  return (
    <Suspense fallback={<div style={{ width: size, height: size }} />}>
      <LucideIcon size={size} {...rest} />
    </Suspense>
  );
}

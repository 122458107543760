// JsFromRoutes CacheKey 4ba8a5b8477951e1a5f8dd87042f69c5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/otp_app/new'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/c/:company_slug/otp_app'),
  create: /* #__PURE__ */ definePathHelper('post', '/c/:company_slug/otp_app'),
}

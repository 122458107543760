import { companies } from "@/api";
import MainPanel from "@/layouts/components/main-panel";
import Nav from "@/layouts/components/nav";
import useCurrentCompany from "@/lib/hooks/use-current-company";
import { ReactNode } from "react";
import CompanyDropdown from "./components/company-dropdown";
import DashboardProviders from "./components/dashboard-providers";
import { getDashboardTabs } from "./components/nav/tabs";
import Profile from "./components/profile";

const DashboardLayout = ({ children }: { children: ReactNode }) => {
  const company = useCurrentCompany();

  return (
    <DashboardProviders>
      <Nav
        header={<CompanyDropdown />}
        tabs={getDashboardTabs()}
        footer={<Profile />}
        theme={{ header: { logo: { href: companies.show.path(company) } } }}
      />
      <MainPanel>{children}</MainPanel>
    </DashboardProviders>
  );
};

export default (page: ReactNode) => <DashboardLayout>{page}</DashboardLayout>;

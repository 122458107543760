// JsFromRoutes CacheKey 9609dc48562eab2eabbeb2326f636e24
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/admin/payments'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/payments/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/payments/:id'),
}

// JsFromRoutes CacheKey 4af9ca49989b647cc6a0baa4d77d2472
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/inventory'),
  create: /* #__PURE__ */ definePathHelper('post', '/c/:company_slug/inventory'),
  new: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/inventory/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/c/:company_slug/inventory/:id/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/c/:company_slug/inventory/:id'),
}

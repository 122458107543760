// JsFromRoutes CacheKey b157d315c433dd9513092c8014ca51a8
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  updateRevolutStatus: /* #__PURE__ */ definePathHelper('post', '/admin/draws/:id/update_revolut_status'),
  index: /* #__PURE__ */ definePathHelper('get', '/admin/draws'),
  show: /* #__PURE__ */ definePathHelper('get', '/admin/draws/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/admin/draws/:id'),
}

import {
  addresses,
  adminCompanies,
  adminCompaniesCreditLineRequests,
  adminCompaniesCreditLines,
  adminCompaniesDraws,
  adminCompaniesFinancials,
  adminCompaniesInventory,
  adminCompaniesPayments,
  adminCompaniesReviews,
  adminCompaniesScores,
  adminCreditLineRequests,
  adminCreditLines,
  adminDraws,
  adminInventoryItems,
  adminPayments,
  bankAccounts,
  companies,
  contacts,
  creditLine,
  creditLineRequest,
  creditLineRequestsSteps,
  draws,
  inventory,
  members,
  newDrawSteps,
  otpApp,
  payments,
  people,
  profile,
  security,
} from "@/api";
import Icon from "@/components/ui/icon";
import PingIndicator from "@/components/ui/ping-indicator";
import useCurrentCompany from "@/lib/hooks/use-current-company";
import usePageProp from "@/lib/hooks/use-page-prop";
import { AdminPending } from "@/types/serializers";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface Tab {
  name: ReactNode;
  href: string;
  isActive: boolean;
  icon: ReturnType<typeof Icon>;
}

const RedDot = () => <PingIndicator color="red" className="mt-1.5 ml-1.5" />;

export const getDashboardTabs = (): Tab[] => {
  const company = useCurrentCompany();
  const { t } = useTranslation();
  return [
    {
      name: t("overview"),
      href: companies.show.path(company),
      isActive: companies.show.isCurrent(),
      icon: <Icon size={24} name="home" />,
    },
    {
      name: t("underwriting"),
      href: creditLineRequest.new.path(company),
      isActive: [
        creditLineRequest.new.isCurrent(),
        creditLineRequest.show.isCurrent(),
        creditLineRequestsSteps.show.isCurrent(),
        creditLine.show.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="landmark" />,
    },
    {
      name: t("inventory.title"),
      href: inventory.index.path(company),
      isActive:
        inventory.index.isCurrent() ||
        inventory.new.isCurrent() ||
        inventory.edit.isCurrent(),
      icon: <Icon size={24} name="tag" />,
    },
    {
      name: t("draws.title"),
      href: draws.index.path(company),
      isActive:
        draws.index.isCurrent() ||
        draws.new.isCurrent() ||
        newDrawSteps.show.isCurrent(),
      icon: <Icon size={24} name="dollar-sign" />,
    },
    {
      name: t("payments.title"),
      href: payments.index.path(company),
      isActive: payments.index.isCurrent(),
      icon: <Icon size={24} name="hand-coins" />,
    },
    null, // separator
    {
      name: t("myCompany"),
      href: companies.edit.path(company),
      isActive: [
        companies.edit.isCurrent(),
        addresses.edit.isCurrent(),
        people.index.isCurrent(),
        contacts.edit.isCurrent(),
        people.index.isCurrent(),
        people.new.isCurrent(),
        people.edit.isCurrent(),
        bankAccounts.index.isCurrent(),
        bankAccounts.edit.isCurrent(),
        bankAccounts.new.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="briefcase" />,
    },
    {
      name: t("settings.title"),
      href: profile.edit.path(company),
      isActive: [
        profile.edit.isCurrent(),
        otpApp.new.isCurrent(),
        security.show.isCurrent(),
        members.index.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="settings" />,
    },
  ] as Tab[];
};

export const getAdminTabs = (): Tab[] => {
  const pending = usePageProp<AdminPending>("pending");

  return [
    {
      name: <>Companies {pending.companies && <RedDot />}</>,
      href: adminCompanies.index.path(),
      isActive: [
        adminCompanies.index.isCurrent(),
        adminCompaniesReviews.show.isCurrent(),
        adminCompaniesFinancials.show.isCurrent(),
        adminCompaniesScores.show.isCurrent(),
        adminCompaniesCreditLineRequests.show.isCurrent(),
        adminCompaniesCreditLines.show.isCurrent(),
        adminCompaniesInventory.show.isCurrent(),
        adminCompaniesDraws.show.isCurrent(),
        adminCompaniesPayments.show.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="briefcase" />,
    },
    {
      name: (
        <>Inventory Line Requests {pending.creditLineRequests && <RedDot />}</>
      ),
      href: adminCreditLineRequests.index.path(),
      isActive: [
        adminCreditLineRequests.index.isCurrent(),
        adminCreditLineRequests.show.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="landmark" />,
    },
    {
      name: <>Inventory Lines {pending.creditLines && <RedDot />}</>,
      href: adminCreditLines.index.path(),
      isActive: [
        adminCreditLines.index.isCurrent(),
        adminCreditLines.show.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="credit-card" />,
    },
    {
      name: <>Inventory {pending.inventoryItems && <RedDot />}</>,
      href: adminInventoryItems.index.path(),
      isActive: [
        adminInventoryItems.index.isCurrent(),
        adminInventoryItems.show.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="tag" />,
    },
    {
      name: <>Withdrawals {pending.draws && <RedDot />}</>,
      href: adminDraws.index.path(),
      isActive: [
        adminDraws.index.isCurrent(),
        adminDraws.show.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="dollar-sign" />,
    },
    {
      name: <>Payments {pending.payments && <RedDot />}</>,
      href: adminPayments.index.path(),
      isActive: [
        adminPayments.index.isCurrent(),
        adminPayments.show.isCurrent(),
      ].some((x) => x),
      icon: <Icon size={24} name="hand-coins" />,
    },
  ] as Tab[];
};

import Icon from "@/components/ui/icon";
import { useToast } from "@/components/ui/use-toast";
import { useEffect } from "react";
import useFlashNotifications from "./use-flash-notifications";
import usePageProp from "./use-page-prop";

export default function useFlashToasts() {
  const flash = useFlashNotifications();
  const errors = usePageProp<Record<string, string>>("errors");
  const baseError = errors["base"];

  const { toast } = useToast();

  return useEffect(() => {
    if (flash?.success) {
      toast({
        ...flash.success,
        action: flash.success.icon && (
          <Icon name={flash.success.icon} size={48} />
        ),
      });
      return;
    }

    if (flash?.alert) {
      toast({
        ...flash.alert,
        variant: "destructive",
        action: flash.alert.icon && <Icon name={flash.alert.icon} size={48} />,
      });
      return;
    }

    if (baseError) {
      toast({
        title: "Error",
        description: baseError,
        variant: "destructive",
      });
    }
  }, [flash]);
}

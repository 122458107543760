import LoadingDots from "@/components/loading-states/loading-dots";
import i18n from "@/i18n";
import useLocale from "@/lib/hooks/use-locale";
import { ReactNode, useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";

export default function LanguageProvider({
  children,
}: {
  children: ReactNode;
}) {
  const locale = useLocale();
  const [languageSet, setLanguageSet] = useState(false);

  useEffect(() => {
    const changeLanguage = async () => {
      await i18n.changeLanguage(locale);
      setLanguageSet(true);
    };
    changeLanguage();
  }, [locale]);

  return (
    <I18nextProvider i18n={i18n} defaultNS={"common"}>
      {languageSet ? children : <LoadingDots fullScreen />}
    </I18nextProvider>
  );
}

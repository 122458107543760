// JsFromRoutes CacheKey b4ff259968f5808491bfcce3bcdd3fe4
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/company/new'),
  create: /* #__PURE__ */ definePathHelper('post', '/company'),
  edit: /* #__PURE__ */ definePathHelper('get', '/c/:slug/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/c/:slug'),
  update: /* #__PURE__ */ definePathHelper('patch', '/c/:slug'),
}
